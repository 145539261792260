export default function TargetIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="#4E575E"
      viewBox="0 0 256 256"
      {...props}
    >
      <rect width="256" height="256" fill="none"></rect>
      <circle
        cx="128"
        cy="128"
        r="92"
        fill="none"
        stroke="#4E575E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></circle>
      <line
        x1="128"
        y1="36"
        x2="128"
        y2="76"
        fill="none"
        stroke="#4E575E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="36"
        y1="128"
        x2="76"
        y2="128"
        fill="none"
        stroke="#4E575E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="128"
        y1="220"
        x2="128"
        y2="180"
        fill="none"
        stroke="#4E575E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
      <line
        x1="220"
        y1="128"
        x2="180"
        y2="128"
        fill="none"
        stroke="#4E575E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      ></line>
    </svg>
  );
}
